import React from "react";

const FeatureCard = ({ item }) => {
  return (
    <div className="w-[410px] p-1 mt-3">
      <h1 className="satoshi-500 text-[18px] sm:text-[20px] md:text-[24px] w-[85%]">
        {item.heading}
      </h1>
      <p className="satoshi-500 text-[13px] sm:text-[17px]  mt-2 w-[85%] mb-2 text-[--gray-dark]">
        {item.description}
      </p>
      <div className="w-[95%] md:w-[85%] h-[250px] rounded-md">
        <img
          className="w-full h-full rounded-md"
          src={item.img}
          alt="feature background image"
        />
      </div>
    </div>
  );
};

export default FeatureCard;
