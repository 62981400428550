import React from "react";
import svg from "../Assets/carsoulSvg.png";
import CarsoulCard from "./Card/CarsoulCard";

const Testimonial = () => {
  return (
    <div className="flex justify-center items-center w-full relative overflow-hidden">
      <div className="sm:w-[90%] md:w-[80%] lg:w-[70%] bg-white min-h-[180px] rounded-md my-10">
        <CarsoulCard />
      </div>
      <img
        className="absolute hidden md:block bottom-[-1px]  left-0 w-full h-[200px] z-[-3]"
        src={svg}
        alt="footer svg"
      />
    </div>
  );
};

export default Testimonial;
