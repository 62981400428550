import React, { useState } from "react";
import Container from "./Wrappers/Container";
import img1 from "../Assets/img1.png";
import img2 from "../Assets/img2.png";
import img3 from "../Assets/img3.png";

const Header = () => {
  const [dropDownOpen, setDropdown] = useState(false);
  return (
    <Container
      customStyle={
        "w-full px-4 pt-20 md:pt-28 sm:pb-4 md:pb-16 relative header"
      }
    >
      <div className="w-[60%] sm:w-1/2 flex justify-evenly items-end ">
        {/* left section  */}

        <div className=" w-[80%] pb-5">
          <h1 className="w-[100%] satoshi-500 text-[16px] text-[--primary] sm:text-[20px] md:text-[26px]  lg:text-[37px] xl:text-[45px] ">
            Take Financial Wellness Into Your Own Hands
          </h1>
          <h5 className="mt-3 text-[10px] sm:text-[15px] md:text-[22px] lg:text-[28px] satoshi-500">
            We’re the planning tools you didn’t know you needed, until now.
          </h5>
          <div>
            <button
              onClick={() => {
                setDropdown(!dropDownOpen);
              }}
              className="bg-[--primary]  mt-3 py-2 px-3 text-[12px] sm:text-[14px] md:text-[18px] text-white rounded-md satoshi-500"
            >
              Get started Free
            </button>
            <div
              onMouseLeave={() => setDropdown(false)}
              className={`rounded-md top-[20%] left-[30%] flex justify-start items-start flex-col px-3 w-[150px] sm:w-[170px] md:w-[210px] lg:w-[230px] py-4 transition-scale ease-in-out duration-200 bg-[--primary] text-white scale-0 ${
                dropDownOpen ? "scale-100" : "scale-0"
              }`}
            >
              <a
                href={"https://www.coach.smartfbp.com"}
                className="text-[12px] sm:text-[14px] md:text-[15px] lg:text-[16px] my-1 block transition-bg ease-in-out duration-500  hover:bg-[#0e295b] satoshi-500 w-full pl-3 pr-2 py-3 rounded-md"
              >
                Start as a Coach
              </a>

              <a
                href={"http://www.client.smartfbp.com/signup/smart"}
                className="text-[12px] sm:text-[14px] md:text-[15px] lg:text-[16px] my-1 block transition-bg ease-in-out duration-500  hover:bg-[#0e295b] satoshi-500 w-full pl-3 pr-2 py-3 rounded-md"
              >
                Start as a Client
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* right div of images  */}

      <div className="w-[40%] sm:w-1/2  flex justify-center items-end">
        <div className="flex justify-start items-center">
          <div className="w-[90px] h-[90px] sm:w-[150px] sm:h-[180px] md:w-[180px] md:h-[200px]  lg:w-[200px] lg:h-[280px] xl:w-[210px] xl:h-[300px]">
            <img className="w-full h-full" src={img1} alt="Large banner" />
          </div>
          <div className="ml-2">
            <img
              className="w-[60px] h-[40px] sm:w-[110px] sm:h-[90px] md:w-[150px] md:h-[110px] lg:w-[190px] lg:h-[150px] xl:w-[220px] xl:h-[150px]"
              src={img2}
              alt="small banner"
            />
            <img
              className="w-[60px] h-[40px] sm:w-[110px] sm:h-[90px]  md:w-[150px] md:h-[110px] lg:w-[190px] lg:h-[150px] xl:w-[220px] xl:h-[150px] mt-2"
              src={img3}
              alt="small banner"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Header;
