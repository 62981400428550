import React from "react";
import { FaChevronCircleRight, FaChevronCircleLeft } from "react-icons/fa";

const CarsoulCard = () => {
  return (
    <div className="w-full h-full flex justify-center items-center ">
      <div className="w-[10%] h-full flex justify-center items-center text-2xl text-[--primary]">
        <FaChevronCircleLeft className="cursor-pointer" />
      </div>
      <div className="w-[78%] flex justify-center items-start py-8 flex-col">
        <p className="px-2 text-[12px] sm:text-[14px]  md:text-[17px]">
          I just wanted to share a quick note and let you know that you guys do
          a really good job. I'm glad I decided to work with you. It's really
          great how easy your websites are to update and manage. I never have
          any problem at all
        </p>
        <div className="flex justify-start items-center px-2 mt-4">
          <span className="w-[20px] h-[2px] bg-black rounded-md"></span>
          <h1 className="ml-2 satoshi-500 text-[15px] sm:text-[18px] md-[text-22px]">
            Yaseen
          </h1>
        </div>
      </div>
      <div className="w-[10%] h-full flex justify-center items-center text-2xl text-[--primary]">
        <FaChevronCircleRight className="cursor-pointer" />
      </div>
    </div>
  );
};

export default CarsoulCard;
