import React from "react";
import Container from "../Wrappers/Container";

const Card = ({ img, heading, description }) => {
  return (
    <div className=" relative w-[140px] h-[140px] sm:w-[170px] rounded-full sm:h-[170px] py-5 hexagonCard bg-white px-4">
      <Container customStyle={"flex-col"}>
        <img
          className="w-[30px] h-[30px] sm:w-[40px] sm:h-[40px]"
          src={img}
          alt="card icon"
        />
        <h5 className="satoshi-500 text-[14px] sm:text-[18px] mt-2 capitalize">
          {heading} :
        </h5>
        <p className="text-[8px] sm:text-[12px] text-center mt-[4px]">
          {description}
        </p>
      </Container>
    </div>
  );
};

export default Card;
