import React, { useState } from "react";
import { navitems } from "../Data/NavData";
import primaryLogo from "../Assets/logoPrimary.png";
import whiteLogo from "../Assets/logoWhite.png";
import { CgMenuRight } from "react-icons/cg";
import { RiCloseLine } from "react-icons/ri";
import { HiChevronDown } from "react-icons/hi";

const Navbar = () => {
  const [primaryNav, setPrimaryNav] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [responsiveDropdown, setResponsiveDropdown] = useState(false);
  const [open, setOpen] = useState(false);

  window.addEventListener("scroll", () => {
    const nav = document.querySelector(".nav");
    if (nav) {
      if (window.scrollY > 50) {
        nav.classList.add("animateMenu");
        setPrimaryNav(true);
      } else {
        nav.classList.remove("animateMenu");
        setPrimaryNav(false);
      }
    }
  });

  return (
    <nav className="px-10 py-4  text-black flex justify-between fixed  top-0 left-0 z-50 w-full nav">
      <a
        href="/"
        className="w-[70px] h-[20px] sm:w-[90px] sm:h-[26px] md:w-[100px] md:h-[30px] xl:w-[110px] xl:h-[30px]"
      >
        <img
          className="w-full h-full z-30 relative"
          src={primaryNav ? whiteLogo : primaryLogo}
          alt="Smartfbp logo"
        />
      </a>
      <ul className="hidden lg:flex justify-center gap-16 items-center">
        {navitems.map((item, index) => {
          return (
            <li
              onClick={() => {
                setOpen(!open);
              }}
              onMouseLeave={() => setOpen(false)}
              key={index}
              className="!relative cursor-pointer satoshi-500 px-3"
            >
              <h1 className="inline text-white ">{item.label}</h1>
              {item.subMenu && (
                <HiChevronDown
                  className={`text-white text-[20px] inline ml-2 transition-rotate ease-in-out duration-500 ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                />
              )}
              {item.subMenu && (
                <div
                  onMouseLeave={() => setOpen(false)}
                  onMouseEnter={() => setOpen(true)}
                  className={`absolute rounded-sm top-[140%] left-[-50%] flex justify-start items-start flex-col px-3 w-[230px] py-4 transition-scale ease-in-out duration-200 bg-[--primary] text-white scale-0 ${
                    open ? "scale-100" : "scale-0"
                  }`}
                >
                  {item.subMenuItems.map((subLink, subIndex) => {
                    return (
                      <a
                        href={subLink.link}
                        key={subIndex}
                        className="my-1 block transition-bg ease-in-out duration-500  hover:bg-[#0e295b] satoshi-500 w-full pl-3 pr-2 py-3 rounded-md"
                      >
                        {subLink.label}
                      </a>
                    );
                  })}
                </div>
              )}
            </li>
          );
        })}
      </ul>

      <div className="cursor-pointer flex justify-center items-center relative lg:hidden text-center  text-3xl sm:text-4xl md:text-4xl text-white ">
        {menuOpen ? (
          <RiCloseLine
            className="z-50"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          />
        ) : (
          <CgMenuRight
            className="z-50"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          />
        )}

        <ul
          className={`${
            menuOpen ? "top-[-100%] right-[-100%]" : "top-[0%] right-[-4000%]"
          } absolute  bg-[--primary] px-8 z-20 py-10 w-[320px]  transition-all duration-500 ease-in-out`}
        >
          {navitems.map((menuItem, index) => {
            return (
              <li
                key={index}
                className="text-white text-lg satoshi-500 cursor-pointer px-2 py-3 my-4 first:!mt-16 text-start transition-bg ease-in-out duration-500  hover:bg-[#0e295b] pl-3 rounded-md"
                onClick={() => {
                  if (menuItem.subMenu)
                    setResponsiveDropdown(!responsiveDropdown);
                }}
              >
                <h1 className="inline capitalize w-full">{menuItem.label}</h1>
                {menuItem.subMenu && (
                  <HiChevronDown
                    className={` transition-rotate ease-in-out duration-300 float-right ${
                      responsiveDropdown ? "rotate-180" : "rotate-0"
                    }`}
                  />
                )}
                {menuItem.subMenu && (
                  <div
                    className={`${
                      responsiveDropdown ? "block" : "hidden"
                    } flex justify-start items-start flex-col`}
                  >
                    {menuItem.subMenuItems.map((subLink, subIndex) => {
                      return (
                        <a
                          href={subLink.link}
                          key={subIndex}
                          className="text-white my-2 block transition-all ease-in-out duration-500 hover:bg-[--primary] w-full pl-4 rounded-md py-1"
                        >
                          {subLink.label}
                        </a>
                      );
                    })}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>

    // <nav className="flex justify-start flex-col items-end transition-all ease-in-out duration-300 gap-4 z-50 fixed top-0 left-0 w-full ">
    //   <div className="pl-6 pr-4 sm:pl-12 lg:pr-12 py-5 w-full flex justify-between items-center nav">
    //     <a
    //       href="/"
    //       className="w-[70px] h-[20px] sm:w-[90px] sm:h-[30px] md:w-[110px] md:h-[35px] "
    //     >
    //       <img
    //         className="w-full h-full z-30 relative"
    //         src={primaryNav ? whiteLogo : primaryLogo}
    //         alt=""
    //       />
    //     </a>
    //     <div className="w-1/2 hidden lg:flex justify-evenly items-center flex-wrap">
    //       {navitems.map((item, index) => {
    //         return (
    //           <li
    //             key={index}
    //             className="text-white satoshi-500 cursor-pointer px-3"
    //           >
    //             {item.name}
    //           </li>
    //         );
    //       })}
    //     </div>

    //     {/* <div className="hidden lg:flex justify-evenly items-center flex-wrap">
    //       <button className="mr-8 text-white satoshi-500">Sign In</button>
    //       <button className="bg-white py-2 px-3 text-black rounded-md satoshi-500">
    //         Get started Free
    //       </button>
    //     </div> */}

    //     <div className="cursor-pointer flex justify-center items-center lg:hidden text-center relative z-50 text-3xl sm:text-4xl md:text-4xl text-white ">
    //       {menuOpen ? (
    //         <RiCloseLine
    //           onClick={() => {
    //             setMenuOpen(!menuOpen);
    //           }}
    //         />
    //       ) : (
    //         <CgMenuRight
    //           onClick={() => {
    //             setMenuOpen(!menuOpen);
    //           }}
    //         />
    //       )}
    //     </div>
    //   </div>
    //   <div
    //     className={`${
    //       menuOpen ? "menu-open" : "close-menu"
    //     } w-full md:w-1/2 rounded-xl bg-[--primary] pt-24 pb-10 flex justify-center items-center lg:hidden  relative z-40`}
    //   >
    //     <Container customStyle={`flex-col w-full !items-center text-center`}>
    //       {navitems.map((item, index) => {
    //         return (
    //           <li
    //             key={index}
    //             className="text-white text-lg satoshi-500 cursor-pointer px-2 py-6 w-1/2"
    //           >
    //             {item.name}
    //           </li>
    //         );
    //       })}
    //     </Container>
    //   </div>
    // </nav>
  );
};

export default Navbar;
