export const navitems = [
  {
    label: "Home",
    path: "/",
    subMenu: false,
  },
  {
    label: "About",
    path: "/",
    subMenu: false,
  },
  {
    label: "Enterprises",
    path: "/",
    subMenu: true,
    subMenuItems: [
      {
        label: "Staff",
        link: "https://www.staff.smartfbp.com",
      },
      {
        label: "Coach",
        link: "https://www.coach.smartfbp.com",
      },
      {
        label: "Customer",
        link: "https://www.client.smartfbp.com",
      },
    ],
  },
];
