import React from "react";
import Navbar from "../Components/Navbar";
import Header from "../Components/Header";
import CardSection from "../Components/CardSection";
import taxIcon from "../Assets/taxes.png";
import bagIcon from "../Assets/bag.png";
import debitIcon from "../Assets/debit.png";
import location from "../Assets/location.png";
import Features from "../Components/Features";
import Testimonial from "../Components/Testimonial";
import Footer from "../Components/Footer";

const cardData = [
  {
    heading: "Taxes",
    description: "State modeling, itemized deductions,brackets",
    img: taxIcon,
  },
  {
    heading: "Income",
    description: "Work, Social Security, annuities, pensions",
    img: bagIcon,
  },
  {
    heading: "Real Estate",
    description: "Future purchases and sales",
    img: location,
  },
  {
    heading: "Debit",
    description: "Student loans, mortgages, credit cards",
    img: debitIcon,
  },
  {
    heading: "Taxes",
    description: "State modeling, itemized deductions,brackets",
    img: taxIcon,
  },
  {
    heading: "Income",
    description: "Work, Social Security, annuities, pensions",
    img: bagIcon,
  },
  {
    heading: "Real Estate",
    description: "Future purchases and sales",
    img: location,
  },
  {
    heading: "Debit",
    description: "Student loans, mortgages, credit cards",
    img: debitIcon,
  },
];

const Home = () => {
  return (
    <>
      <Navbar />
      {/* <section className="w-full h-[600px] bg-green-900"></section> */}
      <Header />
      <CardSection data={cardData} />
      <Features />
      {/* <div className="w-[97%] mx-auto px-6 pt-5 pb-3">
        <button className="px-4 py-2 bg-[--primary] rounded-md text-white satoshi-500">
          How it works
        </button> */}
      {/* <a
          href="https://smarfin-customer.vercel.app/"
          className="text-blue-500 ml-4 mr-4 px-3 py-2 hover:bg-blue-600 hover:text-white border border-blue-500 rounded-md"
        >
          customer dashboard
        </a> */}
      {/* <a
          href="https://smartfin-staff.vercel.app/"
          className="text-blue-500 ml-4 mr-4 px-3 py-2 hover:bg-blue-600 hover:text-white border border-blue-500 rounded-md"
        >
          staff dashboard
        </a>
        <a
          href="https://smartfin-coach.vercel.app/"
          className="text-blue-500 ml-4 mr-4 px-3 py-2 hover:bg-blue-600 hover:text-white border border-blue-500 rounded-md"
        >
          coach dashboard
        </a>
        <a
          href="https://smartfin-admin.vercel.app/"
          className="text-blue-500 ml-4 mr-4 px-3 py-2 hover:bg-blue-600 hover:text-white border border-blue-500 rounded-md"
        >
          Admin dashboard
        </a> */}
      {/* </div> */}
      <Testimonial />
      <Footer />
    </>
  );
};

export default Home;
