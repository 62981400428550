import React from "react";
import { FaFacebook, FaPhoneAlt } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaAnglesRight } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { MdLocationOn } from "react-icons/md";

const Footer = () => {
  return (
    <div className="flex justify-between items-start flex-wrap gap-6 px-10 pb-8 pt-16 bg-[--primary] text-white">
      {/* about smartfin  */}
      <div className="flex-grow basis-72 pb-5">
        <h1 className="satoshi-600 uppercase text-[16px] md:text-[20px] lg:text-[24px]">
          SMARTFIN
        </h1>
        <p className="my-4 text-[13px] sm:text-[14px] max-w-[80%]">
          We are a leading full-service digital and web development company in
          Dubai, We have every solution under one roof to grow your business.
        </p>
        <div className="flex justify-start items-center mb-3 mt-2 gap-6">
          <FaFacebook className="text-[30px]" />
          <AiFillTwitterCircle className="text-[34px]" />
          <IoLogoWhatsapp className="text-[32px]" />
        </div>
      </div>

      {/* service wrapper  */}
      <div className="flex-grow basis-68 pb-5">
        <h1 className="satoshi-600 w-max  uppercase text-[16px] md:text-[20px] lg:text-[24px] border-b border-white">
          SERVICES
        </h1>

        <div className="mt-2">
          {["Coaching", "Financial Advising", "Programs", "support"].map(
            (item, index) => {
              return (
                <div
                  key={index}
                  className="flex my-1 justify-start items-center"
                >
                  <FaAnglesRight />
                  <h5 className="pl-2 text-[14px] sm:text-[16px]">{item}</h5>
                </div>
              );
            }
          )}
        </div>
      </div>

      {/* reach us section  */}
      <div className="flex-grow basis-72 pb-3">
        <h1 className="satoshi-600 w-max  uppercase text-[16px] md:text-[20px] lg:text-[24px] border-b border-white">
          Reach us
        </h1>
        <div className="my-3">
          <div className="flex justify-start items-center mt-2">
            <FaPhoneAlt className="text-2xl" />
            <h2 className="pl-4 text-[14px] sm:text-[16px]">1866-276-1060</h2>
          </div>
          <div className="flex justify-start items-center mt-3 ">
            <TfiEmail className="text-2xl" />
            <h2 className="pl-4 text-[14px] sm:text-[16px]">coach@zicap.com</h2>
          </div>
          <div className="flex justify-start items-center mt-3 ">
            <MdLocationOn className="text-2xl" />
            <h2 className="pl-2 text-[14px] sm:text-[16px] w-[90%]">
              4846 N. UNIVERSITY DR LAUDERHILL FL 33351
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
