import React from "react";
import Container from "./Wrappers/Container";
import Card from "./Card/Card";

const CardSection = ({ data }) => {
  return (
    <div className="pt-16 pb-7">
      <div className="w-[97%] px-6 mx-auto mb-8">
        <h1 className="w-[100%] satoshi-500 text-[19px] sm:text-[23px] md:text-[28px] lg:text-[35px] text-[--primary] ">
          Go beyond your savings and investments
        </h1>
        <h5 className="mt-3 text-[13px] sm:text-[16px] text-[--gray]">
          Sure, savings are important but so are taxes, income, where you live,
          debt, how you spend money and more.
        </h5>
      </div>

      <Container
        customStyle={"bg-[--gray-light] py-8 px-2 sm:px-5 gap-6 sm:gap-20"}
      >
        {data.map((item, index) => {
          return (
            <Card
              key={index}
              img={item.img}
              heading={item.heading}
              description={item.description}
            />
          );
        })}
      </Container>
    </div>
  );
};

export default CardSection;
