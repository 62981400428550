import React from "react";
import feature1 from "../Assets/feature1.png";
import feature2 from "../Assets/feature2.png";
import feature3 from "../Assets/feature3.png";
import feature4 from "../Assets/feature4.png";
import feature5 from "../Assets/feature5.png";
import FeatureCard from "./Card/FeatureCard";

const data = [
  {
    heading: "Calculate the what-ifs",
    description: "Run any scenario that’s meaningful to you. ",
    img: feature1,
  },
  {
    heading: "Real Time Monitoring",
    description:
      "Know where you stand and what you need to do no matter the economic conditions.",
    img: feature2,
  },
  {
    heading: "Coaching, Classes, and Community",
    description:
      "You aren’t alone. Classes, groups, and 1:1 coaching sessions provide support.",
    img: feature3,
  },
  {
    heading: "Visualizations and Insights",
    description:
      "From cash flow to Monte Carlo analysis, get insights you usually need to pay $1000s for.",
    img: feature4,
  },
  {
    heading: "360° Financial Planning",
    description:
      "With every input, we run thousands of calculations to return reliable results.",
    img: feature5,
  },
];

const Features = () => {
  return (
    <div className="w-full py-2">
      <div className="w-[97%] px-6 mx-auto mb-8 mt-4">
        <h1 className="w-[100%] satoshi-500  text-[27px] lg:text-[35px] text-[--primary] ">
          Unlock powerful features
        </h1>
      </div>
      <div className="w-[97%] px-6 mx-auto flex justify-evenly items-start flex-wrap gap-16 pb-5">
        {data.map((item, index) => {
          return <FeatureCard item={item} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Features;
